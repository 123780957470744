.Slick {
  // background: linear-gradient(0deg, #6709C0 -0.28%, #7533B4 74.61%);
  // background-color: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0;

  div {
    width: 100%;
    height: 100vh;
    letter-spacing: 0.02em;
  }
}
